<div class="info-banner" [class]="'--is-' + status">
    @if (!hideClose) {
        <mat-icon class="info-banner__close --is-clickable" (click)="closeEvent.emit()">close</mat-icon>
    }

    @if (!hideIcon) {
        @if (status === "error") {
            <mat-icon class="info-banner__icon">info</mat-icon>
        }
        @if (status === "warning") {
            <mat-icon class="info-banner__icon">warning</mat-icon>
        }
        @if (status === "success") {
            <mat-icon class="info-banner__icon">check_circle</mat-icon>
        }
    }

    <ng-content></ng-content>
</div>
